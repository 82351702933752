export const reserve = [
	"0x21868fCb0D4b262F72e4587B891B4Cf081232726",
	"0x5b046272cb9fde317ab73a836546e52b1f2d81f3",
	"0x519e815db12bff4e28c41753d8f72485d55a9b40",
	"0x040df97224a2d65df277ab4adb58596a42a9698c",
	"0x5231b639babbd14c518176f12a6bb8cd85fd6e70",
	"0x14f97f92da702af1ed4f09c5612dd0fd590403a0",
	"0x0c96e77528ab52422495a5474697e7630504c739",
	"0x01e40c0e12f9bb7457eba8279197f14fa796fcf5",
	"0xe6aa0f8f60f8488e2f259531a48a081cdb8dd766",
	"0x2a76f7df64889a1f20f5b6aa87ebffa9a38ab925",
	"0xc887fadc6baaa8cd234d9104a65b37265522b024",
	"0xafd1e0562c91a933f4b40154045cee71939e95ea",
	"0x01e40c0e12f9bb7457eba8279197f14fa796fcf5",
	"0xbbeb004d1769ec8ce5e3957b9d8aca659fbabd0c",
	"0xc7c0ed2f2d6b140d0babfcc006ff73652f11ec78",
	"0xf1637adedeb89559c70481c3cb4f74ebac80d829",
	"0x5b93ff82faaf241c15997ea3975419dddd8362c5",
	"0xc7c0ed2f2d6b140d0babfcc006ff73652f11ec78",
	"0xe738052de24cdadb701be3a5f92516b72541f74b",
	"0x76cc936e76624149fad83f4391ed4b7ac7b35ee1",
	"0xc18d4c2fee42accfe7bfdce98bfacbabf76242e9",
	"0x5b93ff82faaf241c15997ea3975419dddd8362c5",
	"0x4806688dfccea461b645f08b9cd2f695999276f4",
	"0xb0f5410f02d0cfd85257e037d141cc49147a6e22",
	"0xff1358d5a84cb340acebabcde034b25b18540dc0",
	"0xf08cf21f82579493a4f20246aead68e7ce20a7e7",
	"0x6f54c650190b44ac9efe531380417ccf12d2d06a",
	"0xed41bb902a1bf884f861835197dea07e0c97e372",
	"0xb5d3f57d77fb6f6cf9eb3efae33f9cd22e39e869",
	"0xb5d3f57d77fb6f6cf9eb3efae33f9cd22e39e869",
	"0xcc47fcbfdd6e558fb39cf5ef4d92e59890476b86",
	"0xc73fc103857ea4303a954becbffa19569f7ebec2",
	"0xcc47fcbfdd6e558fb39cf5ef4d92e59890476b86",
	"0xd93aac6b8df6c3f61dc49bb68a4107c0d00a0243",
	"0x4d4ae97c9f8d8391880765c71dedc4ef18f9e762",
	"0xe629117078d0849dce5e2c23b0ef40ab62a672a3",
	"0x2e6645c4ecbdaad32d45bb8b93aa8f84a33c5053",
	"0xdbb70ac885fee2e118fc7d35f1fa29d030e09e11",
	"0x9dbe56e65961146525d796bdc008225bd5915a4f",
	"0x2e6645c4ecbdaad32d45bb8b93aa8f84a33c5053",
	"0x04764f07542f8870168236da61d31c217c4cbc56",
	"0x41db2348f54dbab35908594a3a47e9c3e2c71bfe",
	"0xebda75c5e193bbb82377b77e3c62c0b323240307",
	"0xe6ceb755e9c5706f001eadebe5e47eaf3bec0e11",
	"0xd36954df517cfd9d533d4494b0e62b61c02fc29a",
	"0x04764f07542f8870168236da61d31c217c4cbc56",
	"0xc704389a3f999f325d568df1ab676d4b750f7c37",
	"0x01e90545e7cf9550ec0aad3ee9b91af7b158a221",
	"0x8cb7f1a4f44593ec356b11c70c0a977c647c763c",
	"0xd78bb8dc8c5ff23cc3c1b39f8577ae8a4ebd46d6",
	"0x32d3e3126ad99e48ff769fddc3e26a528b8c2c02",
	"0xe317eb46da9d27aa3493b03ea0468ffd37ccc2e1",
	"0xbfc453ea39e035a155cffe0756c436925d50908d",
	"0xf5d4a3b9fb77d1d5600a10e0e1c4efd12f9be751",
	"0x41db2348f54dbab35908594a3a47e9c3e2c71bfe",
	"0x4806688dfccea461b645f08b9cd2f695999276f4",
	"0xf5d4a3b9fb77d1d5600a10e0e1c4efd12f9be751",
	"0xbeeb02e0f3c98d948bcc890c6c37e9020d16959d",
	"0x9f7ef899becd23360a6f3c96dae9f32b6493b2a2",
	"0xf3bf7cf9e6b96e754f8d0d927f2162683b278322",
	"0x5b4bb3c2bb6e2ec707cc39a86dd398c0a9f69add",
	"0xda4745f0254044e4b702c9301737c2a0b8c69a6a",
	"0x9f7ef899becd23360a6f3c96dae9f32b6493b2a2",
	"0x71a548c09c6d09df38811c6df025d281e878258b",
	"0xb74b57b9ff6ff66616b3f6539b5e7a6aca3cb410",
	"0xc3086007a83a86b81b42958539d17881c1642195",
	"0x53562e8ecee54356dc89ad263a444528b34d6c80",
	"0xf2582f0a1573439a5a45e3cf1cd6e40a4457ef7b",
	"0xc3086007a83a86b81b42958539d17881c1642195",
	"0x53562e8ecee54356dc89ad263a444528b34d6c80",
	"0x2585b94bd758107e6d1698a0786e84efabc36882",
	"0xf2582f0a1573439a5a45e3cf1cd6e40a4457ef7b",
	"0x2585b94bd758107e6d1698a0786e84efabc36882",
	"0x205410fa2246bdd108a13d385fe83f1dc5133279",
	"0x031ee7975fb94847a620ee017441610e97d66605",
	"0x71a548c09c6d09df38811c6df025d281e878258b",
	"0x7a6597adf4ba4e405bf5bbe0f5976dc933b089ae",
	"0xb6cf777e3696a502107417265c92d1b075636a10",
	"0x0c96e77528ab52422495a5474697e7630504c739",
	"0xca9c00a0bb2a70429f308c7781c8f9f5624a439a",
	"0x031ee7975fb94847a620ee017441610e97d66605",
].map((address) => address.toLowerCase());
